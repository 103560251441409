import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/subscribe.scss"

export default () => {
  return (
    <Layout title="Top Podcast for Startups, Software, and Fulfillment">
      <SEO title="Top Podcast for Startups, Software, and Fulfillment" />
      <div className="podcast-embed">
        <iframe
        scrolling="no"
          src="https://castbox.fm/app/castbox/player/id1319061?v=8.22.10&autoplay=0"
          frameBorder="0"
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </Layout>
  )
}
